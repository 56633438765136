'use strict';
define([], function () {
    function controller(
        $scope,
        $translate,
        $mdToast,
        CountriesService,
        $stateParams,
        TrackEventService,
        $rootScope,
    ) {
        var ctrl = this;
        $scope.showCustomToast = showCustomToast;

        ctrl.$onInit = function () {
            $scope.locations = ctrl.locations;
            $scope.wizardMode = ctrl.wizardMode;
            $scope.configuration = {
                device: 1,
                engine: 1
            };
            // TRACKING
            var stoTool = $stateParams.tool === 'seo-spider' ? 'SEO Spider' : 'Rank Tracker';
            var eventLabel = $rootScope.stoFrom ? stoTool + ' - ' + $rootScope.stoFrom : stoTool;
            if ($scope.wizardMode) {
                TrackEventService.event(
                    'Suite Usages',
                    'SEO Project - Step - Set Locations', eventLabel);
                TrackEventService.gaV2Event(
                    'project_wizard', {
                        step: 'Set Locations',
                        from: $rootScope.stoFrom,
                        type: stoTool
                    });
            }
            //
        };

        function showCustomToast(string, classIcon) {
            $mdToast.show({
                template:
                    '<md-toast layout="row" flex class="md-toast max-w">' +
                    '   <div class="p-t p-b"><i class="' + classIcon + ' m-r-sm"></i>' + $translate.instant(string) + '</div>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right',
                parent: angular.element('.toast-show')
            });
        }

        $scope.emptyConfiguration = '<i class="icon-blackhole custom-message-template-icon"></i>' +
            '<div class="md-subhead m-b p-l p-r">' +
            $translate.instant('project.wizard.no.configuration') +
            '</div>';

        $scope.locationController = {
            removeConfiguration: function (index) {
                $scope.locations.splice(index, 1);
            },
            setDevice: function (idDevice) {
                if (!idDevice) {
                    return;
                }
                var listDevice = [
                    {id: 1, name: 'desktop'}, {id: 2, name: 'mobile'}
                ];
                var deviceName = '';
                angular.forEach(listDevice, function (obj) {
                    if (obj.id == idDevice) {
                        deviceName = obj.name;
                    }
                })
                return deviceName;
            },
            setSearchEngine: function (idSearchEngine) {
                if (!idSearchEngine) {
                    return;
                }
                var listSearchEngine = [
                    {id: 1, name: 'Google'}, {id: 2, name: 'Yahoo'}, {id: 4, name: 'Bing'}
                ];
                var searchEngineName = '';
                angular.forEach(listSearchEngine, function (obj) {
                    if (obj.id == idSearchEngine) {
                        searchEngineName = obj.name;
                    }
                })
                return searchEngineName;
            },
            getCountryNameByCountryCode: function(countryCode) {
                return CountriesService.getByCode(countryCode).name;
            }
        };

        $scope.$watch('configuration.engine', function (searchEngineId) {
            if (searchEngineId === 2 || searchEngineId === 3) {
                $scope.configuration.device = 1;
            }
        });

        $scope.$watchCollection('locations', function (locations) {
            $scope.locations = [];
            $scope.locations = locations;
        });

        $scope.addLocation = function (configuration) {
            var isPresent = false;
            angular.forEach($scope.locations, function (location) {
                if (
                    location.searchEngineId === configuration.searchEngineId &&
                    location.countryCode === configuration.countryCode &&
                    location.deviceId == configuration.deviceId
                ) {
                    isPresent = true;
                }
            });

            if (isPresent) {
                showCustomToast('project.wizard.configuration.is.insert', 'icon-x st-red');
                return;
            }

            $scope.$watch('configuration.engine', function (searchEngineId) {
                if (searchEngineId === 2 || searchEngineId === 3) {
                    $scope.configuration.device = 1;
                }
            });

            if (!isPresent) {
                $scope.locations.push(configuration);
            }
        };
    }

    controller.$inject = [
        '$scope',
        '$translate',
        '$mdToast',
        'CountriesService',
        '$stateParams',
        'TrackEventService',
        '$rootScope',
    ];

    return {
        templateUrl: 'app/modules/project/wizard/steps/set-locations/set-locations.html',
        bindings: {
            locations: '=',
            wizardMode: '<'
        },
        controller: controller,
    };
});
