'use strict';
define(['app'], function (app) {
    app.service('PlanDetailsService', PlanDetailsService);
    PlanDetailsService.$inject = [];

    function PlanDetailsService() {
        var self = this;

        /**
         *
         * NB: l'array promoStack serve ad impilare oggetti di tipo Promo, in maniera da gestire più promozioni consecutive senza l'intervento umano
         * si prega di scegliere la startPromoDate e la endPromoDate in maniera sensata e realistica, un piccolo algortitmo ne farà uso
         * per capire se c'è una promozione attiva, e se si, qual è.
         *
         * NB2: attualmente esistono 2 tipi di promo,
         * le 'all-platform-promo' comportano modifiche a tutta la piattaforma compreso pricing e onboardiong
         * le 'only-dashboard-promo' comportano modifiche solo alla dashboard e alla topbar
         *
         * NB3: la classe Date salta un mese, dunque Gennaio = 0, Febbraio = 1, ecc..
         *
         */
        self.promoStack = [
            {
                id: 'secret',
                name: "Early Friday Promo",
                promoType: 'all-platform-promo',
                // years / month - 1 / day
                startPromoDate: new Date(2024, 10, 4, 0, 0, 0),
                endPromoDate: new Date(2024, 10, 17, 23, 59, 59), //real secret friday end-date
                promoCoupon: 'EARLYFRIDAY2024',
                currentPromoModifierClass: 'black-friday-special',
                promoImgFile: 'Early_Friday_Promo.png',
                promoAlt: 'secret friday 2024',
                // bannerCTAButtonText: 'Iscriviti in lista d’attesa',
                // bannerCTALink: 'https://seotesteronline.typeform.com/secret-friday/?utm_source=suite&utm_campaign=secret-friday',
                // bannerCTADescription: 'La <span>Promo Segreta</span> che non puoi <br> perdere per <span> ottimizzare la SEO!</span>',
                // bannerLabelText: 'SCOPRI LA PROMO SEGRETA',
                languageVisibility: 'it',
                discount: 50,
                nextDiscount: 40,
                promoPlans: {
                    'Lite': {
                        annualAmount: 9,
                        annualInsteadAmount: 26,
                        annualAmountSavedForYear: 204,
                    },
                    'Pro': {
                        annualAmount: 13,
                        annualInsteadAmount: 38,
                        annualAmountSavedForYear: 300,
                    },
                    'Plus': {
                        annualAmount: 19,
                        annualInsteadAmount: 56,
                        annualAmountSavedForYear: 444,
                    },
                    'Advanced': {
                        annualAmount: 24,
                        annualInsteadAmount: 72,
                        annualAmountSavedForYear: 576,
                    },
                    'Premium': {
                        annualAmount: 48,
                        annualInsteadAmount: 144,
                        annualAmountSavedForYear: 1152,
                    },
                    'Infinity': {
                        annualAmount: 148,
                        annualInsteadAmount: 444,
                        annualAmountSavedForYear: 3352,
                    }
                }
            },
            {
                id: 'black',
                name: "Black Friday Promo",
                promoType: 'all-platform-promo',
                //startPromoDate: Date.now(),
                startPromoDate: new Date(2024, 10, 18, 0, 0, 0), //real secret friday start-date
                endPromoDate: new Date(2024, 11, 1, 23, 59, 59), //real secret friday end-date
                promoCoupon: 'BLACKFRIDAY2024',
                currentPromoModifierClass: 'black-friday-special',
                promoImgFile: 'blackfriday2022.svg',
                promoAlt: 'black friday 2024',
                languageVisibility: 'all',
                discount: 40,
                nextDiscount: 30,
                promoPlans: {
                    'Lite': {
                        annualAmount: '10,80',
                        annualInsteadAmount: 26,
                        annualAmountSavedForYear: '182,40',
                    },
                    'Pro': {
                        annualAmount: '15,60',
                        annualInsteadAmount: 38,
                        annualAmountSavedForYear: '268,80',
                    },
                    'Plus': {
                        annualAmount: '22,80',
                        annualInsteadAmount: 56,
                        annualAmountSavedForYear: '398,40',
                    },
                    'Advanced': {
                        annualAmount: '28,80',
                        annualInsteadAmount: 72,
                        annualAmountSavedForYear: '518,40',
                    },
                    'Premium': {
                        annualAmount: '57,60',
                        annualInsteadAmount: 144,
                        annualAmountSavedForYear: '1.036,80',
                    },
                    'Infinity': {
                        annualAmount: '177,60',
                        annualInsteadAmount: 444,
                        annualAmountSavedForYear: '3.196,80',
                    }
                }
            },
            {
                id: 'cyber',
                name: "Cyber Monday Promo",
                promoType: 'all-platform-promo',
                startPromoDate: new Date(2024, 11, 2, 0, 0, 0), //real black friday start-date
                endPromoDate: new Date(2024, 11, 8, 23, 59, 59), //real black friday end-date
                promoCoupon: 'CYBERMONDAY2024',
                currentPromoModifierClass: 'black-friday-special',
                promoImgFile: 'cybermonday2022.svg',
                promoAlt: 'cyber monday 2024',
                languageVisibility: 'all',
                discount: 30,
                nextDiscount: 20,
                promoPlans: {
                    'Lite': {
                        annualAmount: '12,60',
                        annualInsteadAmount: 26,
                        annualAmountSavedForYear: '160,80',
                    },
                    'Pro': {
                        annualAmount: '18,20',
                        annualInsteadAmount: 38,
                        annualAmountSavedForYear: '237,60',
                    },
                    'Plus': {
                        annualAmount: '26,60',
                        annualInsteadAmount: 56,
                        annualAmountSavedForYear: '352,80',
                    },
                    'Advanced': {
                        annualAmount: '33,60',
                        annualInsteadAmount: 72,
                        annualAmountSavedForYear: '460,80',
                    },
                    'Premium': {
                        annualAmount: '67,20',
                        annualInsteadAmount: 144,
                        annualAmountSavedForYear: '921,60',
                    },
                    'Infinity': {
                        annualAmount: '207,20',
                        annualInsteadAmount: 444,
                        annualAmountSavedForYear: '2.841,60',
                    }
                }
            },
            {
                id: 'christmas',
                name: "Christmas Promo",
                promoType: 'all-platform-promo',
                startPromoDate: new Date(2024, 11, 9, 0, 0, 0), //real cyber-monday start-date
                endPromoDate: new Date(2025, 0, 12, 23, 59, 59), //real cyber-monday end-date
                promoCoupon: 'CHRISTMASPROMO2024',
                currentPromoModifierClass: 'christmas-promo',
                promoImgFile: 'christmaspromo.svg',
                // promoImgFile: 'cybermonday2022.svg',
                promoAlt: 'Christmas Promo 2024',
                languageVisibility: 'all',
                discount: 20,
                promoPlans: {
                    'Lite': {
                        annualAmount: '14,40',
                        annualInsteadAmount: 26,
                        annualAmountSavedForYear: '139,20',
                    },
                    'Pro': {
                        annualAmount: '20,80',
                        annualInsteadAmount: 38,
                        annualAmountSavedForYear: '206,40',
                    },
                    'Plus': {
                        annualAmount: '30,40',
                        annualInsteadAmount: 56,
                        annualAmountSavedForYear: '307,20',
                    },
                    'Advanced': {
                        annualAmount: '38,40',
                        annualInsteadAmount: 72,
                        annualAmountSavedForYear: '403,20',
                    },
                    'Premium': {
                        annualAmount: '76,80',
                        annualInsteadAmount: 144,
                        annualAmountSavedForYear: '806,40',
                    },
                    'Infinity': {
                        annualAmount: '236,80',
                        annualInsteadAmount: 444,
                        annualAmountSavedForYear: '2.486,40',
                    }
                }
            },
        ];

        self.plans = [
            {
                name: 'Lite',
                type: 'Personal',
                runningOut: false,
                annualInsteadAmount: 0,
                monthlyInsteadAmount: 0,
                annualAmount: 18,
                annualAmountDecimal: 0,
                monthlyAmount: 26,
                annualAmountSavedForYear: 96,
                monthlyAmountSavedForYear: 0,
                trialPeriodDays: 7,
                annualAlias: 'plan-lite-annual',
                monthlyAlias: 'plan-lite-monthly',
                features: [{
                    platform: {
                        users: 1,
                        projects: 1
                    },
                    seoAnalysis: {
                        analisysPerDay: 20,
                        onPageSeoCheck: true,
                        keywordReasearch: true,
                        contentAnalysis: true,
                        serpCompetitorAnalysis: true,
                    },
                    seoCopywritingAssistant: {
                        accessToTheTool: false,
                        suggestionsWithAI: false,
                        contentGeneration: false,
                    },
                    ai: {
                        accessToTheTool: false,
                        aiCredits: false,
                    },
                    onSiteSeoAudit: {
                        crawlablePagesPerMonth: 250,
                        pagesCanBeAnalyzedByProject: 50,
                        dataRetention: '1 Month'
                    },
                    keywordResearch: {
                        resultsPerKeyword: 150,
                        keywordPerDomainPerMonth: 150,
                        keywordList: 2
                    },
                    backlink: {
                        accessToTheTool: false,
                        backlinkRowsPerMonth: false,
                        analisysPerMonth: false,
                    },
                    positionMonitoring: {
                        trackedKeyword: 25,
                        updateFrequency: '7 Days',
                        searchEngines: 'Google / Yahoo / Bing',
                        mobileRanking: true,
                    },
                    report: {
                        exportCsvPerDay: false,
                        dowloadableReport: false,
                        whiteLabelSeoReports: false,
                    },
                    leadGeneration: {
                        formAllowed: 'Infinity',
                        leadIncludedPerMonth: false,
                        leadGenWhitelabelReports: false,
                    },
                    writeAssistant: {
                        accessToTheTool: false,
                        suggestionsWithAI: false,
                        contentGeneration: false,
                    },
                },],
                textualTarget: "for.those.who.want.to.start.with.seo",
                textualFeatures: [
                    "on.page.seo.audit",
                    "keyword.research",
                    "1.seo.project",
                    ""
                ]
            },
            {
                name: 'Pro',
                type: 'Personal',
                runningOut: false,
                annualInsteadAmount: 0,
                monthlyInsteadAmount: 0,
                annualAmount: 26,
                annualAmountDecimal: 0,
                monthlyAmount: 38,
                annualAmountSavedForYear: 144,
                monthlyAmountSavedForYear: 0,
                trialPeriodDays: 7,
                annualAlias: 'plan-pro-annual',
                monthlyAlias: 'plan-pro-monthly',
                features: [{
                    platform: {
                        users: 1,
                        projects: 2
                    },
                    seoAnalysis: {
                        analisysPerDay: 100,
                        onPageSeoCheck: true,
                        keywordReasearch: true,
                        contentAnalysis: true,
                        serpCompetitorAnalysis: true,
                    },
                    seoCopywritingAssistant: {
                        accessToTheTool: true,
                        suggestionsWithAI: true,
                        contentGeneration: true,
                    },
                    ai: {
                        accessToTheTool: true,
                        aiCredits: 60,
                    },
                    onSiteSeoAudit: {
                        crawlablePagesPerMonth: 2500,
                        pagesCanBeAnalyzedByProject: 500,
                        dataRetention: '3 Month'
                    },
                    keywordResearch: {
                        resultsPerKeyword: 1500,
                        keywordPerDomainPerMonth: 1500,
                        keywordList: 10
                    },
                    backlink: {
                        accessToTheTool: true,
                        backlinkRowsPerMonth: 25000,
                        analisysPerMonth: 100,
                    },
                    positionMonitoring: {
                        trackedKeyword: 150,
                        updateFrequency: '7 Days',
                        searchEngines: 'Google / Yahoo / Bing',
                        mobileRanking: true,
                    },
                    report: {
                        exportCsvPerDay: 10,
                        dowloadableReport: 5,
                        whiteLabelSeoReports: false,
                    },
                    leadGeneration: {
                        formAllowed: 'Infinity',
                        leadIncludedPerMonth: 10,
                        leadGenWhitelabelReports: false,
                    },
                },],
                textualTarget: "for.smb.and.startup",
                textualFeatures: [
                    "all.the.lite.features",
                    "extended.limits",
                    "seo.content.analysis",
                    "seo.reports"
                ]
            },
            {
                name: 'Plus',
                type: 'Personal',
                runningOut: true,
                annualInsteadAmount: 0,
                monthlyInsteadAmount: 0,
                annualAmount: 38,
                annualAmountDecimal: 0,
                monthlyAmount: 56,
                annualAmountSavedForYear: 216,
                monthlyAmountSavedForYear: 0,
                trialPeriodDays: 7,
                annualAlias: 'plan-plus-annual',
                monthlyAlias: 'plan-plus-monthly',
                features: [{
                    platform: {
                        users: 1,
                        projects: 5
                    },
                    seoAnalysis: {
                        analisysPerDay: 200,
                        onPageSeoCheck: true,
                        keywordReasearch: true,
                        contentAnalysis: true,
                        serpCompetitorAnalysis: true,
                    },
                    seoCopywritingAssistant: {
                        accessToTheTool: true,
                        suggestionsWithAI: true,
                        contentGeneration: true,
                    },
                    ai: {
                        accessToTheTool: true,
                        aiCredits: 300,
                    },
                    onSiteSeoAudit: {
                        crawlablePagesPerMonth: 10000,
                        pagesCanBeAnalyzedByProject: 2000,
                        dataRetention: '3 Month'
                    },
                    keywordResearch: {
                        resultsPerKeyword: 5000,
                        keywordPerDomainPerMonth: 5000,
                        keywordList: 25
                    },
                    backlink: {
                        accessToTheTool: true,
                        backlinkRowsPerMonth: 50000,
                        analisysPerMonth: 200,
                    },
                    positionMonitoring: {
                        trackedKeyword: 500,
                        updateFrequency: '7 Days',
                        searchEngines: 'Google / Yahoo / Bing',
                        mobileRanking: true,
                    },
                    report: {
                        exportCsvPerDay: 50,
                        dowloadableReport: 25,
                        whiteLabelSeoReports: true,
                    },
                    leadGeneration: {
                        formAllowed: 'Infinity',
                        leadIncludedPerMonth: 20,
                        leadGenWhitelabelReports: true,
                    }
                },],
                textualTarget: "for.seo.consultant",
                textualFeatures: [
                    "all.the.pro.features",
                    "extended.limits",
                    "white.label.reports",
                    "5.seo.projects"
                ]
            },
            {
                name: 'Advanced',
                type: 'Agency',
                runningOut: true,
                annualInsteadAmount: 0,
                monthlyInsteadAmount: 0,
                annualAmount: 48,
                annualAmountDecimal: 0,
                monthlyAmount: 72,
                annualAmountSavedForYear: 288,
                monthlyAmountSavedForYear: 0,
                trialPeriodDays: 7,
                annualAlias: 'plan-advanced-annual',
                monthlyAlias: 'plan-advanced-monthly',
                features: [{
                    platform: {
                        users: 5,
                        projects: 15
                    },
                    seoAnalysis: {
                        analisysPerDay: 500,
                        onPageSeoCheck: true,
                        keywordReasearch: true,
                        contentAnalysis: true,
                        serpCompetitorAnalysis: true,
                    },
                    seoCopywritingAssistant: {
                        accessToTheTool: true,
                        suggestionsWithAI: true,
                        contentGeneration: true,
                    },
                    ai: {
                        accessToTheTool: true,
                        aiCredits: 750,
                    },
                    onSiteSeoAudit: {
                        crawlablePagesPerMonth: 50000,
                        pagesCanBeAnalyzedByProject: 5000,
                        dataRetention: '3 Month'
                    },
                    keywordResearch: {
                        resultsPerKeyword: 10000,
                        keywordPerDomainPerMonth: 20000,
                        keywordList: 50
                    },
                    backlink: {
                        accessToTheTool: true,
                        backlinkRowsPerMonth: 100000,
                        analisysPerMonth: 400,
                    },
                    positionMonitoring: {
                        trackedKeyword: 1000,
                        updateFrequency: '7 Days',
                        searchEngines: 'Google / Yahoo / Bing',
                        mobileRanking: true,
                    },
                    report: {
                        exportCsvPerDay: 100,
                        dowloadableReport: 50,
                        whiteLabelSeoReports: true,
                    },
                    leadGeneration: {
                        formAllowed: 'Infinity',
                        leadIncludedPerMonth: 50,
                        leadGenWhitelabelReports: true,
                    }
                },],
                textualTarget: "for.growing.seo.and.ppc.agencies",
                textualFeatures: [
                    "5.users.included",
                    "15.seo.projects",
                    "50.white.label.reports",
                    ""
                ]
            },
            {
                name: 'Premium',
                type: 'Agency',
                runningOut: false,
                annualInsteadAmount: 0,
                monthlyInsteadAmount: 0,
                annualAmount: 96,
                annualAmountDecimal: 0,
                monthlyAmount: 144,
                annualAmountSavedForYear: 576,
                monthlyAmountSavedForYear: 0,
                trialPeriodDays: 7,
                annualAlias: 'plan-premium-annual',
                monthlyAlias: 'plan-premium-monthly',
                features: [{
                    platform: {
                        users: 10,
                        projects: 50
                    },
                    seoAnalysis: {
                        analisysPerDay: 2000,
                        onPageSeoCheck: true,
                        keywordReasearch: true,
                        contentAnalysis: true,
                        serpCompetitorAnalysis: true,
                    },
                    seoCopywritingAssistant: {
                        accessToTheTool: true,
                        suggestionsWithAI: true,
                        contentGeneration: true,
                    },
                    ai: {
                        accessToTheTool: true,
                        aiCredits: 1500,
                    },
                    onSiteSeoAudit: {
                        crawlablePagesPerMonth: 500000,
                        pagesCanBeAnalyzedByProject: 10000,
                        dataRetention: '6 Months'
                    },
                    keywordResearch: {
                        resultsPerKeyword: 25000,
                        keywordPerDomainPerMonth: 50000,
                        keywordList: 100
                    },
                    backlink: {
                        accessToTheTool: true,
                        backlinkRowsPerMonth: 250000,
                        analisysPerMonth: 1000,
                    },
                    positionMonitoring: {
                        trackedKeyword: 2500,
                        updateFrequency: '5 Days',
                        searchEngines: 'Google / Yahoo / Bing',
                        mobileRanking: true,
                    },
                    report: {
                        exportCsvPerDay: 250,
                        dowloadableReport: 150,
                        whiteLabelSeoReports: true,
                    },
                    leadGeneration: {
                        formAllowed: 'Infinity',
                        leadIncludedPerMonth: 200,
                        leadGenWhitelabelReports: true,
                    }
                },],
                textualTarget: "for.large.seo.and.ppc.agencies",
                textualFeatures: [
                    "all.the.advanced.features",
                    "10.users.included",
                    "50.seo.projects",
                    "150.white.label.reports"
                ]
            },
            {
                name: 'Infinity',
                type: 'Agency',
                runningOut: false,
                annualInsteadAmount: 0,
                monthlyInsteadAmount: 0,
                annualAmount: 296,
                annualAmountDecimal: 0,
                monthlyAmount: 444,
                annualAmountSavedForYear: 1776,
                monthlyAmountSavedForYear: 0,
                trialPeriodDays: 7,
                annualAlias: 'plan-infinity-annual',
                monthlyAlias: 'plan-infinity-monthly',
                features: [{
                    platform: {
                        users: 25,
                        projects: 'Infinity'
                    },
                    seoAnalysis: {
                        analisysPerDay: 'Infinity',
                        onPageSeoCheck: true,
                        keywordReasearch: true,
                        contentAnalysis: true,
                        serpCompetitorAnalysis: true,
                    },
                    seoCopywritingAssistant: {
                        accessToTheTool: true,
                        suggestionsWithAI: true,
                        contentGeneration: true,
                    },
                    ai: {
                        accessToTheTool: true,
                        aiCredits: 4500,
                    },
                    onSiteSeoAudit: {
                        crawlablePagesPerMonth: 1250000,
                        pagesCanBeAnalyzedByProject: 25000,
                        dataRetention: '12 Months'
                    },
                    keywordResearch: {
                        resultsPerKeyword: 50000,
                        keywordPerDomainPerMonth: 150000,
                        keywordList: 'Infinity'
                    },
                    backlink: {
                        accessToTheTool: true,
                        backlinkRowsPerMonth: 1000000,
                        analisysPerMonth: 3000,
                    },
                    positionMonitoring: {
                        trackedKeyword: 5000,
                        updateFrequency: '3 Days',
                        searchEngines: 'Google / Yahoo / Bing',
                        mobileRanking: true,
                    },
                    report: {
                        exportCsvPerDay: 'Infinity',
                        dowloadableReport: 'Infinity',
                        whiteLabelSeoReports: true,
                    },
                    leadGeneration: {
                        formAllowed: 'Infinity',
                        leadIncludedPerMonth: 'Infinity',
                        leadGenWhitelabelReports: true,
                    }
                },],
                textualTarget: "for.highly.structured.marketing.agencies",
                textualFeatures: [
                    "all.the.premium.features",
                    "25.users.included",
                    "infinite.seo.projects",
                    "infinite.whitelabel.reports"
                ]
            },
        ];

        return {
            getAll: getAll,
            getPlansByType: getPlansByType,
            addFeaturesDetail: addFeaturesDetail,
            isPromoTime: isPromoTime,
            getCurrentPromo: getCurrentPromo,
            setPromoTimeToFalse: setPromoTimeToFalse
        };

        /**
         * @param plans
         * @param type
         * @returns {*}
         */
        function addFeaturesDetail(plans, type) {
            plans.map(function (plan) {
                plan.features = [];
                switch (plan.name) {
                    case 'Plan Lite':
                        plan.features = [{
                            text: 'access.seo.projects',
                            value: 1
                        },
                            {
                                text: 'access.analysis.per.day',
                                value: 25
                            },
                            {
                                text: 'access.monitored.keywords',
                                value: 25
                            },
                            {
                                text: 'access.scan.resources',
                                value: 250
                            },
                            {
                                text: 'access.backlink.analysis',
                                value: false,
                                showEver: true
                            },
                            {
                                text: 'access.whitelabel.reporting',
                                value: false,
                                showEver: true
                            },
                        ];
                        return;
                    case 'Plan Pro':
                        if (type === 'personal' || !type) {
                            plan.features = [{
                                text: 'access.seo.projects',
                                value: 2
                            },
                                {
                                    text: 'access.analysis.per.day',
                                    value: 100
                                },
                                {
                                    text: 'access.monitored.keywords',
                                    value: 150
                                },
                                {
                                    text: 'access.scan.resources',
                                    value: 2500
                                },
                                {
                                    text: 'access.downloadable.backlinks',
                                    value: 25000,
                                    showEver: true
                                },
                                {
                                    text: 'access.whitelabel.reporting',
                                    value: false,
                                    showEver: true
                                },

                            ];
                        } else if (type === 'personal pro') {
                            plan.features = [{
                                text: 'access.seo.projects',
                                value: 2
                            },
                                {
                                    text: 'access.analysis.per.day',
                                    value: 100
                                },
                                {
                                    text: 'access.monitored.keywords',
                                    value: 150
                                },
                                {
                                    text: 'access.scan.resources',
                                    value: 2500
                                },
                                {
                                    text: 'access.downloadable.backlinks',
                                    value: 25000,
                                },
                                {
                                    text: 'access.whitelabel.reporting',
                                    value: false,
                                },
                            ];
                        }
                        return;
                    case 'Plan Plus':
                        plan.features = [{
                            text: 'access.seo.projects',
                            value: 5,
                        },
                            {
                                text: 'access.analysis.per.day',
                                value: 200
                            },
                            {
                                text: 'access.monitored.keywords',
                                value: 500
                            },
                            {
                                text: 'access.scan.resources',
                                value: 10000
                            },
                            {
                                text: 'access.downloadable.backlinks',
                                value: 50000
                            },
                            {
                                text: 'access.whitelabel.reporting',
                                value: 25
                            },
                        ];
                        return;
                    case 'Plan Advanced':
                        plan.features = [{
                            text: 'access.users.included',
                            value: 5
                        },
                            {
                                text: 'access.seo.projects',
                                value: 15,
                            },
                            {
                                text: 'access.monitored.keywords',
                                value: 1000
                            },
                            {
                                text: 'access.scan.resources',
                                value: 50000
                            },
                            {
                                text: 'access.downloadable.backlinks',
                                value: 100000
                            },
                            {
                                text: 'access.whitelabel.reporting',
                                value: 50
                            },
                        ];
                        return;
                    case 'Plan Premium':
                        plan.features = [{
                            text: 'access.users.included',
                            value: 10
                        },
                            {
                                text: 'access.seo.projects',
                                value: 50
                            },
                            {
                                text: 'access.monitored.keywords',
                                value: 2500
                            },
                            {
                                text: 'access.scan.resources',
                                value: 500000
                            },
                            {
                                text: 'access.downloadable.backlinks',
                                value: 250000
                            },
                            {
                                text: 'access.whitelabel.reporting',
                                value: 150
                            },
                        ];
                        return;
                }
                return plan;
            });

            return plans;
        }

        function convertToInteger(strNumber) {
            strNumber = strNumber.toString();
            strNumber = strNumber.trim();
            const parts = strNumber.split(',');
            return parseInt(parts[0], 10);
        }

        function extractDecimalPart(strNumber) {
            strNumber = strNumber.toString();
            strNumber = strNumber.trim();
            const parts = strNumber.split(',');

            if (parts.length > 1) {
                return parseInt(parts[1]);
            }

            return null;
        }


        /**
         * @returns {*[]|*}
         */
        function getAll() {
            if (isPromoTime() && getCurrentPromo().promoType === 'all-platform-promo') {
                return self.plans.map(function (plan) {
                    if (getCurrentPromo().promoPlans[plan.name]) {
                        plan.annualAmount = convertToInteger(getCurrentPromo().promoPlans[plan.name].annualAmount);
                        plan.annualAmountDecimal = extractDecimalPart(getCurrentPromo().promoPlans[plan.name].annualAmount);
                        plan.annualInsteadAmount = getCurrentPromo().promoPlans[plan.name].annualInsteadAmount;
                        plan.annualAmountSavedForYear = getCurrentPromo().promoPlans[plan.name].annualAmountSavedForYear;
                    }

                    return plan;
                }).filter(function (plan) {
                    return plan.name !== 'Lite' || plan.name !== 'Pro';
                });
            }

            return self.plans;
        }

        /**
         * @returns {*[]|*}
         */
        function getPlansByType(type) {
            var plans = getAll().filter(function (plan) {
                return plan.type === type;
            });


            if (!plans || plans.length <= 0) {
                plans = getAll().filter(function (plan) {
                    return plan.type === 'Agency';
                });
            }

            return plans;
        }

        function isPromoTime() {
            var forcedPromo;
            if (location.search) {
                var id = new URLSearchParams(location.search).get('promo');

                if (id !== 'black' && id !== 'cyber' && id !== 'secret' && id !== 'christmas') {
                    sessionStorage.removeItem('forcedPromo');
                } else {
                    sessionStorage.setItem('forcedPromo', id);
                }

                if (sessionStorage.getItem('forcedPromo')) {
                    forcedPromo = sessionStorage.getItem('forcedPromo');
                }
            }

            var now = Date.now();
            for (var promo of self.promoStack) {
                if (forcedPromo && promo.id === forcedPromo || (now >= promo.startPromoDate && now < promo.endPromoDate)) {
                    return true;
                }
            }

            return false;
        }

        function setPromoTimeToFalse() {
            self.isPromoTime = false;
        }

        function getCurrentPromo() {
            var forcedPromo;
            if (location.search) {
                var id = new URLSearchParams(location.search).get('promo');

                if (id !== 'black' && id !== 'cyber' && id !== 'secret' && id !== 'christmas') {
                    sessionStorage.removeItem('forcedPromo');
                } else {
                    sessionStorage.setItem('forcedPromo', id);
                }

                if (sessionStorage.getItem('forcedPromo')) {
                    forcedPromo = sessionStorage.getItem('forcedPromo');
                }
            }

            var now = Date.now();
            for (var promo of self.promoStack) {
                if (forcedPromo && promo.id === forcedPromo || (now >= promo.startPromoDate && now < promo.endPromoDate)) {
                    return promo;
                }
            }

            return null;
        }
    }
});
