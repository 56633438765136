'use strict';
define([], function () {
    var defaultColumnFields = [{
        customClass: 'm-w-sm text-left',
        emptyState: 'square-line',
        sortBy: 'keyword',
        label: 'keyword.list.component.keyword'
    },
        {
            label: 'keyword.tracker.position',
            customClass: 'column-normal',
            sortBy: 'position',
        },
        {
            label: 'keyword.tracker.difference',
            customClass: 'column-normal',
            sortBy: 'difference',
        },
        {
            label: 'keyword.tracker.vol',
            sortBy: 'avgVolume',
            customClass: 'hide-xs column-small'
        },
        {
            label: 'keyword.tracker.indexed',
            customClass: 'hide-xs column-normal',
            sortBy: 'searches[0].count'
        },
        {
            label: 'keyword.tracker.top.ranked',
            customClass: 'text-left hide-xs',
            emptyState: 'two-lines',
        },
        {
            label: 'Loc.',
            customClass: 'column-medium',
        },
    ];

    function controller($scope, $interval, $timeout) {
        var ctrl = this;
        ctrl.competitorsSelected = [];

        setController();

        function setController() {
            $scope.trackerController = {
                project: ctrl.project,
                competitorsSelected: ctrl.competitorsSelected,
                isExpanded: ctrl.isExpanded,
                setDevice: function (idDevice) {
                    if (!idDevice) {
                        return;
                    }
                    var listDevice = [{
                        id: 1,
                        name: 'desktop'
                    }, {
                        id: 2,
                        name: 'mobile'
                    }];
                    var deviceName = '';
                    angular.forEach(listDevice, function (obj) {
                        if (obj.id === idDevice) {
                            deviceName = obj.name;
                        }
                    });
                    return deviceName;
                },
                setSearchEngine: function (idSearchEngine) {
                    if (!idSearchEngine) {
                        return;
                    }
                    var listSearchEngine = [{
                        id: 1,
                        name: 'Google'
                    }, {
                        id: 2,
                        name: 'Yahoo'
                    }, {
                        id: 4,
                        name: 'Bing'
                    }];
                    var searchEngineName = '';
                    angular.forEach(listSearchEngine, function (obj) {
                        if (obj.id === idSearchEngine) {
                            searchEngineName = obj.name;
                        }
                    });
                    return searchEngineName;
                },
                getRankedForThisCompetitor: function (row, idxCompetitorsSelected) {
                    var competitor;

                    angular.forEach(ctrl.project.competitors, function (obj) {
                        if (obj.linkUrl === ctrl.competitorsSelected[idxCompetitorsSelected]) {
                            competitor = obj;
                        }
                    });

                    if (!competitor) {
                        return;
                    }

                    if (!row.competitorSearchesRanked) {
                        row.competitorSearchesRanked = {};
                    }

                    if (!row || !row.competitorSearches) {
                        row.competitorSearchesRanked[competitor.id] = {
                            positionForSortBy: 101,
                        };
                        return;
                    }

                    var searches = row.competitorSearches[competitor.id];
                    if (!searches || !(searches instanceof Array) || searches.length <= 0) {
                        row.competitorSearchesRanked[competitor.id] = {
                            positionForSortBy: 101
                        };
                        return;
                    }

                    var lastItem = searches[searches.length - 1];
                    row.competitorSearchesRanked[competitor.id] = {
                        position: searches[0].position,
                        positionForSortBy: parseInt(searches[0].position),
                        difference: lastItem.position - searches[0].position,
                        pages: searches[0].pages,
                        name: competitor.linkUrl,
                        color: competitor.color
                    };
                },
                getCompetitorId: function (idxCompetitorsSelected) {
                    if (!ctrl.project || !ctrl.project.competitors || !(ctrl.project.competitors instanceof Array)) {
                        return;
                    }

                    var competitor;
                    angular.forEach(ctrl.project.competitors, function (obj) {
                        if (obj.linkUrl === ctrl.competitorsSelected[idxCompetitorsSelected]) {
                            competitor = obj;
                        }
                    });

                    if (!competitor) return '';
                    return competitor.id;
                },
                getDifferenceRanked: function (row) {
                    if (!row || !row.searches || row.searches.length <= 0) {
                        return {
                            positionForSortBy: 101
                        };
                    }

                    var difference = 0;
                    if (row.searches.length > 1) {
                        difference = row.searches[row.searches.length - 1].position - row.position;
                    }

                    return {
                        position: row.position,
                        positionForSortBy: parseInt(row.position),
                        difference: difference || 0.1 ,
                        linkUrl: row.searches[0].linkUrl,
                        linkUrlParsed: parsedURL(row.searches[0].linkUrl),
                        linkProject: ctrl.project.linkUrl
                    };
                },
                trendPerKeyword: function (row) {
                    ctrl.keywordSelected = row;
                }
            };
        }

        function parsedURL(url) {
            try {
                return new URL(url);
            } catch (err) {
                return {
                    pathname: url,
                    hostname: null,
                };
            }
        }

        function cleanUrl(url) {
            url = url.replace('https://', '');
            url = url.replace('http://', '');
            url = url.replace('www.', '');
            url = url.replace(/^www\./i, '');
            url = url.replace(/\/$/i, '');
            return url.trim();
        }

        $scope.checkboxSelected = function (rowsSelected) {
            ctrl.rowsSelected = rowsSelected;
        };

        $scope.fields = defaultColumnFields;

        setFields();

        function setFields() {
            var fields = defaultColumnFields;
            if (ctrl.competitorsSelected.length) {
                fields = [
                    {
                        customClass: 'm-w-sm text-left',
                        emptyState: 'square-line',
                        sortBy: 'keyword',
                        label: 'keyword.list.component.keyword'
                    },
                    competitorsColumns(),
                    {
                        label: 'Loc.',
                        customClass: 'column-medium',
                    },
                ];
            }
            $scope.fields = fields.flat();
        }

        var competitorsIds = [];

        $scope.$watchCollection('$ctrl.project.competitors', function (competitors) {
            if (!competitors) {
                return;
            }

            competitorsIds = [];
            for (var i = 0; i < competitors.length; i++) {
                if (ctrl.competitorsSelected.indexOf(competitors[i].linkUrl) >= 0) {
                    competitorsIds.push(competitors[i].id);
                }
            }
        });

        $scope.$watchCollection('$ctrl.competitorsSelected', function (competitorsSelected) {
            ctrl.competitorsSelected = competitorsSelected;
            if (ctrl.project) {
                setFields();
                setController();
            }
        });

        $scope.$watchCollection('$ctrl.rows', function (rows) {
            if (!rows.length) {
                return;
            }

            $timeout(function () {
                for (var i = 0; i < rows.length; i++) {
                    angular.forEach(competitorsIds, function (competitorId) {
                        if (rows[i].competitorSearches && rows[i].competitorSearches[competitorId]) {
                            if (!rows[i].competitorSearchesRanked) {
                                rows[i].competitorSearchesRanked = {};
                            }

                            rows[i].competitorSearchesRanked[competitorId] = {
                                positionForSortBy: 101
                            };
                        }
                    });
                }

                if (ctrl.project) {
                    setFields();
                    setController();
                    rows.forEach(function (row) {
                        setGrow(row);
                    });
                }
            });
        });

        $scope.$on("updateKeywords", function (evt, rows) {
            if (!rows.length) {
                return;
            }
            ctrl.rows = rows;
            ctrl.grow = {
                up: 0,
                down: 0,
                unchanged: 0
            };
            ctrl.rows.forEach(function (row) {
                if (row.isLoading) {
                    row.isLoading = false;
                }
                setGrow(row);
            });
        });

        $scope.$watch('$ctrl.project', function (project) {
            ctrl.project = project;
            setMessageEmptyData();
            if (ctrl.project) {
                setFields();
                setController();
            }
        });

        function setMessageEmptyData() {
            var ctaButton = '';
            if (ctrl.project && ctrl.project.id) {
                ctaButton = '<md-button class="st-button__rounded--md--blue--outline w-sm m-t-sm"' +
                    '   ui-sref="app.keywordtracker.addkeywords({trackerProjectId:' + ctrl.project.id + '})">' +
                    '    <i class="icon-plus"></i>' +
                    '        {{\'keyword.tracker.landing.add.keywords\' | translate}}' +
                    '</md-button>';
            }
            $scope.messageEmptyData = '<i class="icon-no-data-kw custom-message-template-icon"></i>' +
                '<div class="md-subhead m-b-xs">' +
                '   {{\'keyword.tracker.nodata.keywords\'|translate}}' +
                '</div>' + ctaButton;
        }

        function isEmpty(obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        }

        function competitorsColumns() {
            var columns = [];
            columns.push({
                label: '<div class="flex layout-row layout-align-center-center">' +
                    '<div class="st-badge-circle st-bg-lightblue"></div>' +
                    '<div class="m-l-xs md-truncate">' + ctrl.project.name + '</div></div>',
                customClass: 'column-medium hide-xs',
                sortBy: 'position',
            });
            ctrl.competitorsSelected.filter(function (competitorSelected) {
                ctrl.project.competitors.some(function (competitor) {
                    if (competitor.linkUrl === competitorSelected) {
                        columns.push({
                            label: '<div class="flex layout-row layout-align-center-center">' +
                                '<div class="st-badge-circle" style="background-color:' + competitor.color + '"></div>' +
                                '<div class="m-l-xs md-truncate">' + cleanUrl(competitor.linkUrl) + '</div></div>',
                            customClass: 'column-medium hide-xs',
                            sortBy: 'positionCompetitor' + competitor.id,
                        });
                    }
                });
            });

            return columns;
        }

        function setGrow(row) {
            if (!row || !row.searches || row.searches.length <= 0) {
                return;
            }

            if (row.searches.length === 1) {
                ctrl.grow.unchanged += 1;
                return;
            }

            var lastItem = row.searches[row.searches.length - 1];
            var difference = lastItem.position - row.position;

            if (difference === 0) {
                ctrl.grow.unchanged += 1;
            }
            if (difference < 0) {
                ctrl.grow.down += 1;
            }
            if (difference > 0) {
                ctrl.grow.up += 1;
            }
        }
    }

    controller.$inject = ['$scope', '$interval', '$timeout'];

    return {
        templateUrl: 'app/modules/keywordtracker/components/table-fluid-competitors/table-fluid-competitors.html',
        bindings: {
            rows: '=',
            competitorsSelected: '<',
            rowsSelected: "=",
            keywordSelected: '=',
            project: '<',
            grow: "=",
            tableInLoading: '<',
            isExpanded: '<'
        },
        controller: controller,
    };
});
